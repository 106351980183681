import React, { useEffect, useReducer, useState, useContext } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { quoteContext } from '../../../provider';
import axios from 'axios';
import '../../assets/styles/pages/quote/step-six.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProgressBar from '../../components/quotes/progress-bar';
import QuoteNav from '../../components/quotes/quote-nav';
import Logo from '../../images/dinggo-logo.inline.svg';

/* global window */
const QuoteStepSix = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        email
        phone
      }
    }
  `);
  const { general } = data;
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [confirmPopUpCopy, setConfirmPopUpCopy] = useState(false);
  const [contactDetails, setContactDetails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '',
      email: '',
      mobile: '',
      sms: '',
      terms: ''
    }
  );
  const [contactDetailsValid, setContactDetailsValid] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: true,
      email: true,
      mobile: true,
      sms: true,
      terms: true
    }
  );
  let { quoteForm } = useContext(quoteContext);

  useEffect(() => {
    if (Object.keys(quoteForm).length > 0) {
      setContactDetails(quoteForm.contactDetails);
    } else {
      navigate('/quote');
    }
  }, [quoteForm]);

  const validateForm = (name, value) => {
    const re = /\S+@\S+\.\S+/;

    if (name === 'name') {
      if (value.length === 0) {
        setContactDetailsValid({ 'name': false });
      } else {
        setContactDetailsValid({ 'name': true });
      }
    }
    if (name === 'email') {
      if (value.length === 0 || !re.test(value)) {
        setContactDetailsValid({ 'email': false });
      } else {
        setContactDetailsValid({ 'email': true });
      }
    }
    if (name === 'mobile') {
      if (value.length !== 10 || isNaN(value)) {
        setContactDetailsValid({ 'mobile': false });
      } else {
        setContactDetailsValid({ 'mobile': true });
      }
    }
    if (name === 'terms') {
      if (!value) {
        setContactDetailsValid({ 'terms': false });
      } else {
        setContactDetailsValid({ 'terms': true });
      }
    }
  };

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    setContactDetails({ [name]: value });
    validateForm(name, value);
  };

  const navigateToConfirmation = () => {
    navigate('/quote/confirmation');
    setLoading(false);
  };

  const handleSubmit = (event, context) => {
    event.preventDefault();

    setLoading(true);
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const hour = d.getHours();
    const mins = d.getMinutes();
    const seconds = d.getSeconds();
    const terms = `${year}-${month}-${date} ${hour}:${mins}:${seconds}`;
    const formData = new FormData();

    if (typeof quoteForm.uploadFiles !== 'undefined') {
      if (Object.keys(quoteForm.uploadFiles).length > 0) {
        Object.keys(quoteForm.uploadFiles).forEach((elem) => {
          formData.append('photos[]', quoteForm.uploadFiles[elem]);
        });
      }
    }

    formData.append('damageType_id', quoteForm.damagedCaused.damageType_id);
    formData.append('damageDescription', quoteForm.description);
	formData.append('atFault', quoteForm.atFault);
    formData.append('state', quoteForm.repairDetails.carRegisteredIn);
    formData.append('vehicle_registration', quoteForm.repairDetails.vehicleRegistrationNumber);
    formData.append('postcode', quoteForm.repairDetails.repairLocation.postcode);
    formData.append('suburb', quoteForm.repairDetails.repairLocation.suburb);
    formData.append('nevdis_id', quoteForm.nevdis_id);
    formData.append('contactlessRepair', (quoteForm.contactlessRepair === 'Contactless Repairers Only') ? 1 : 0);
    formData.append('name', contactDetails.name);
    formData.append('email', contactDetails.email);
    formData.append('mobile', contactDetails.mobile);
    formData.append('sendQuotesViaSMS', (contactDetails.sms) ? 1 : 0);
    formData.append('agreeTerms', (contactDetails.terms) ? terms : '');

    axios.post(`${process.env.GATSBY_PORT}endpoints/submission`,
      formData,
      {
        headers: {
          'accept': 'application/json',
          'content-type': 'multipart/form-data'
        }
      }
    ).then(res => {
      const { data } = res;
      if (data.success) {
        context.addToQuoteForm({
          ...quoteForm,
          bookingRef: data.data.bookingRef,
          email: data.data.email,
          verified: data.data.verified,
          contactDetails: contactDetails
        });

        if (data.data.holidayPopup !== false) {
          setConfirmPopUp(true);
          setConfirmPopUpCopy(data.data.holidayPopup);
        } else {
          navigateToConfirmation();
        }

      } else {
        setErrorMessage(true);
        setLoading(false);
      }
    }).catch(error => {
      console.warn(error);
      setErrorMessage(true);
      setLoading(false);
    });
  };

  const hasContactDetails = () => {
    let valid = true;
    const re = /\S+@\S+\.\S+/;

    if (contactDetails.name.length === 0) {
      valid = false;
    }
    if (contactDetails.email.length === 0) {
      valid = false;
    }
    if (contactDetails.mobile.length !== 10) {
      valid = false;
    }
    if (isNaN(contactDetails.mobile)) {
      valid = false;
    }
    if (!contactDetails.terms) {
      valid = false;
    }
    if (!re.test(contactDetails.email)) {
      valid = false;
    }

    return valid;
  }

  return (
    <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
      <quoteContext.Consumer>
        {context => (
          <React.Fragment>
            <ProgressBar step="6" />
            <SEO title="DingGo | Car Quote - Submit Your Details" description="Upload your photos and describe the damage to compare car repair quotes online, fast and free. Snap. Compare. Repair." />
            <div className="quote-wrapper step-six">
              <div className="container xs">
                <QuoteNav step="6" />
                <h1>Contact Details</h1>
                <p className="intro">
                  Last step. We just need your contact details to tell you when your quotes are ready.
                </p>
                <form onSubmit={(e) => { handleSubmit(e, context); }}>
                  <div className={`form-group ${contactDetailsValid.name ? 'input-valid' : 'input-error'}`}>
                    <label>Your name</label>
                    <input type="text" className="form-control" id="name" name="name" onChange={(e) => { handleInputChange(e); }} value={contactDetails.name} />
                  </div>
                  <div className={`form-group ${contactDetailsValid.email ? 'input-valid' : 'input-error'}`}>
                    <label>Email address</label>
                    <input type="email" className="form-control" id="email" name="email" onChange={(e) => { handleInputChange(e); }} value={contactDetails.email} />
                  </div>
                  <div className={`form-group ${contactDetailsValid.mobile ? 'input-valid' : 'input-error'}`}>
                    <label>Mobile Number</label>
                    <input type="tel" className="form-control" id="mobile" name="mobile" onChange={(e) => { handleInputChange(e); }} value={contactDetails.mobile} />
                  </div>
                  {/* <div className="form-group form-group-switch">
                    <label>
                      Send my quote updates via SMS too
                      <div className="switch">
                        <input type="checkbox" id="sms" name="sms" onChange={(e) => { handleInputChange(e); }} value={contactDetails.sms} />
                        <span className="slider round"></span>
                      </div>
                    </label>
                  </div> */}
                  <div className={`form-group form-group-switch ${contactDetailsValid.terms ? 'input-valid' : 'input-error'}`}>
                    <label className="checkbox-container">I accept the&nbsp;<a href="/termsandconditions">Terms of Use</a>&nbsp;and the&nbsp;<a href="/privacypolicy">Privacy Policy</a>
                      <input type="checkbox" id="terms" name="terms" onChange={(e) => { handleInputChange(e); }} value={contactDetails.terms} />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  {errorMessage && (
                    <p className="error">
                      Hi Sorry we are experiencing a problem submitting your request. Can you please message us on our online chat or email us your issue at <a href={`mailto:${general.email}`}>{general.email}</a> and we will look to resolve your case asap. Thank you!
                    </p>
                  )}
                  <div className="form-btn-wrapper">
                    <button className={`btn ${hasContactDetails() ? '' : 'disable'}`} type="submit" id="button-click-to-confirmation-page">
                      {loading && (
                        <div className="lds-ellipsis">
                          <div />
                          <div />
                          <div />
                          <div />
                        </div>
                      )}
                      {!loading && 'Submit my repair'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div
              className={`popup-overlay ${confirmPopUp ? 'active' : ''}`}
            />
            <div className={`popup-wrapper holiday-popup text-center ${confirmPopUp ? 'active' : ''}`}>
              <Logo alt="DingGo Logo" />
              <div
                className="popup-intro"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: confirmPopUpCopy,
                }}
              />
              <button
                type="button"
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  navigateToConfirmation();
                }}
              >
                Got it
              </button>
            </div>
          </React.Fragment>
        )}
      </quoteContext.Consumer>
    </Layout>
  );
};

export default QuoteStepSix;
